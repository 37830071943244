import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import {FaSeedling, FaLeaf, FaGlobeAfrica, FaArrowRight } from 'react-icons/fa';
import Misuku from '../Images/Misuku.jpg';

function MalawianCoffeeBlogPost() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="bg-stone-100 min-h-screen py-16">
      <div className="container mx-auto px-4 max-w-4xl">
        <motion.h1 
          className="text-5xl font-bold text-center text-stone-800 mb-8"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.5 }}
        >
          Malawian Coffee: A Hidden Gem in African Coffee Production
        </motion.h1>

        <motion.div 
          className="bg-white rounded-lg shadow-lg p-8 mb-8"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <p className="text-stone-600 mb-6 text-lg leading-relaxed">
            Are you a coffee enthusiast looking for a unique and flavorful experience? Look no further than Malawian coffee, a hidden gem in the world of African coffee production. At Basket of Africa, we're proud to import and export high-quality coffee from this remarkable origin. Let's explore the rich history, unique characteristics, and promising future of Malawian coffee.
          </p>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              The Rich History of Coffee in Malawi
              <FaSeedling className="ml-3 text-amber-600 text-3xl" />
            </h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              Malawi, a landlocked country in southeast Africa, has a coffee history dating back to the late 1800s. Dr. John Buchanan introduced the Nyasaland variety, a Bourbon/Typica derivative, from the Royal Botanic Gardens in Edinburgh. Since then, coffee has played a significant role in Malawi's agricultural landscape, despite facing numerous challenges over the years.
            </p>
            <img src={Misuku} alt="Malawian Coffee Farm" className="w-full h-64 object-cover rounded-lg shadow-md mb-4" />
            <p className="text-sm text-stone-500 italic text-center">A picturesque coffee farm in Misuku Hills, Malawi</p>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              Malawian Coffee: A Unique Flavor Profile
            </h2>
            <p className="text-stone-600 mb-4 text-lg leading-relaxed">
              Coffee connoisseurs prize Malawian coffee for its distinctive taste. Here are some key characteristics:
            </p>
            <ul className="list-none text-stone-600 mb-6 pl-4">
              <li className="mb-2 flex items-center"><FaArrowRight className="text-amber-600 mr-2" /> Sweet and delicate flavor</li>
              <li className="mb-2 flex items-center"><FaArrowRight className="text-amber-600 mr-2" /> Floral notes</li>
              <li className="mb-2 flex items-center"><FaArrowRight className="text-amber-600 mr-2" /> Hints of licorice and spice</li>
              <li className="mb-2 flex items-center"><FaArrowRight className="text-amber-600 mr-2" /> 100% Arabica beans</li>
            </ul>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              Basket of Africa: Bringing Malawian Coffee to the World
            </h2>
            <p className="text-stone-600 mb-4 text-lg leading-relaxed">
              As a company dedicated to importing and exporting Malawian coffee, Basket of Africa is at the forefront of showcasing this exceptional origin to the world. We work closely with local cooperatives and farmers to ensure:
            </p>
            <ol className="list-decimal list-inside text-stone-600 mb-6 pl-4 text-lg">
              <li className="mb-2">Fair prices for producers</li>
              <li className="mb-2">Sustainable farming practices</li>
              <li className="mb-2">High-quality coffee production</li>
              <li className="mb-2">Promotion of Malawian coffee in international markets</li>
            </ol>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4">Experience the Taste of Malawi with Basket of Africa</h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              Ready to explore the unique flavors of Malawian coffee? Basket of Africa offers a carefully curated selection of the finest beans from this exceptional origin. Whether you're a coffee shop owner, a roaster, or a passionate home brewer, our Malawian coffee will add a touch of exotic flavor to your coffee experience.
            </p>
          </div>

          <div className="text-center">
            <Link to="/contact">
              <motion.button 
                className="bg-amber-600 hover:bg-amber-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 text-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Contact Us
              </motion.button>
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default MalawianCoffeeBlogPost;