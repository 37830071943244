import React from 'react';
import Hero from './Hero';
import FeaturedProducts from './FeaturedProducts';
import About from './About';
import Blog from './Blog';
import Newsletter from './Newsletter';

function Home() {
  return (
    <div>
      <Hero />
      <FeaturedProducts />
      <About />
      <Blog />
      <Newsletter />
    </div>
  );
}

export default Home;