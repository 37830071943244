import React from 'react';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import { FaLeaf, FaHandsHelping } from 'react-icons/fa';
import imageGeisha from '../Images/imageGeisha.jpg';
import imageBananas from '../Images/imageBananas.jpg';
import CropPlantAnimation from '../Animations/CropPlant.json';

function Products() {
  const productCategories = [
    {
      name: 'Geisha Green Coffee Beans',
      image: imageGeisha,
      description: 'Discover the rich and delicate flavors of Geisha Green Coffee Beans, sourced from the pristine Misuku Hills region in Malawi. Known for their unique fruity and floral notes, our Geisha beans are carefully harvested from sustainable farms, offering a premium coffee experience that reflects the beauty and diversity of African agriculture.',
    },
    {
      name: 'Arabica Green Coffee Beans',
      image: imageGeisha,
      description: 'Experience the rich, bold flavor of our Arabica blend, sourced from the lush regions of Chitipa, Misuku Hills, and northern Malawi. Grown at high altitudes, this premium coffee delivers a smooth, well-balanced taste with subtle hints of fruit and chocolate.',
    },
    {
      name: 'Bananas',
      image: imageBananas,
      description: 'Our bananas, grown in the fertile soils of northern Malawi, offer a naturally sweet and creamy flavor. Harvested with care, they embody the rich agricultural traditions of the region, providing a delicious, healthy snack straight from the heart of Africa.',
    },
  ];

  return (
    <div className="bg-stone-100 min-h-screen py-16">
      <div className="container mx-auto px-4">
        <motion.h1 
          className="text-5xl font-bold text-center text-stone-800 mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Premium Products
        </motion.h1>

        <div className="mx-auto mb-16 w-fit">
          <Lottie 
            animationData={CropPlantAnimation}
            style={{ width: '150px', height: '150px' }} 
            loop={false}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-20">
          {productCategories.map((category, index) => (
            <motion.div 
              key={index}
              className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <motion.img 
                src={category.image} 
                alt={category.name} 
                className="w-full h-64 object-cover"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
              <div className="p-6 flex-grow flex flex-col justify-between">
                <div>
                  <h2 className="text-2xl font-semibold text-stone-800 mb-4">{category.name}</h2>
                  <p className="text-stone-600">{category.description}</p>
                </div>
                <motion.button 
                  className="w-full bg-amber-600 hover:bg-amber-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 mt-6"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Place an Order
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div 
          className="bg-stone-200 rounded-lg p-8 shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <h2 className="text-3xl font-bold text-stone-800 mb-6">Our Sourcing Process & Impact</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <div className="flex items-center mb-4">
                <FaLeaf className="text-3xl text-green-600 mr-4" />
                <h3 className="text-xl font-semibold text-stone-700">Sustainable Sourcing</h3>
              </div>
              <p className="text-stone-600 mb-4">
                At Basket of Africa, we believe in the power of ethical sourcing. We work directly with local farmers in Malawi and other countries, ensuring fair wages and sustainable farming practices. Our partnership model empowers communities and preserves the rich agricultural heritage of the region.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <div className="flex items-center mb-4">
                <FaHandsHelping className="text-3xl text-blue-600 mr-4" />
                <h3 className="text-xl font-semibold text-stone-700">Community Impact</h3>
              </div>
              <p className="text-stone-600 mb-4">
                Every purchase you make creates a ripple effect of positive change. We invest in local infrastructure, education, and farming initiatives, fostering long-term development in the communities we partner with. By choosing our products, you're not just enjoying premium African produce – you're supporting a brighter future for families across Malawi and the broarder southern African region.
              </p>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Products;
