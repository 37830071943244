import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import framer-motion
import imageBananas from '../Images/imageBananas.jpg';
import imageGeisha from '../Images/imageGeisha.jpg';

function FeaturedProducts() {
  const products = [
    { name: 'Geisha Green Coffee Beans', price: 'Price on Request', image: imageGeisha },
    { name: 'Arabica Green Coffee Beans', price: 'Price on Request', image: imageGeisha },
    { name: 'Bananas', price: 'Price on Request', image: imageBananas },
  ];

  // Animation settings for each product card
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 60,
        damping: 20,
      },
    },
  };

  return (
    <div className="py-16 bg-stone-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-stone-800">Season's Produce</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }} // Ensures animation only runs once when in view
              variants={cardVariants}
            >
              <img src={product.image} alt={product.name} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="font-bold text-stone-800">{product.name}</h3>
                <p className="text-stone-600">{product.price}</p>
                <Link
                  to="/contact"
                  className="mt-4 bg-amber-600 hover:bg-amber-700 text-white font-bold py-2 px-4 rounded transition duration-300 inline-block"
                >
                  Enquire
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturedProducts;
