import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaClock } from 'react-icons/fa';
import emailjs from '@emailjs/browser';

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.send('service_z1cpqzf', 'template_lpapgqm', formData, 'pE-KrUU4jHguQv-Wt')
      .then((result) => {
        alert('Message sent successfully!');
        setIsSuccess(true);
        setFormData({ name: '', email: '', message: '' }); // Clear the form
      }, (error) => {
        console.error(error.text);
        alert('Failed to send the message. Please try again.');
      });
  };

  return (
    <div className="bg-[#F5DEB3] py-16">
      <div className="container mx-auto px-4">
        <motion.h1 
          className="text-4xl font-bold text-center text-[#5C4033] mb-12"
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.6 }}
        >
          Contact Us
        </motion.h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Contact Form Section */}
          <motion.div 
            className="lg:col-span-2 bg-white p-8 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h2 className="text-3xl font-semibold text-[#8B4513] mb-6">Get in Touch</h2>
            <form onSubmit={sendEmail}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div>
                  <label htmlFor="name" className="block text-lg text-[#5C4033] mb-2">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-[#8B4513]"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-lg text-[#5C4033] mb-2">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-[#8B4513]"
                    required
                  />
                </div>
              </div>
              <div className="mb-6">
                <label htmlFor="message" className="block text-lg text-[#5C4033] mb-2">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-[#8B4513]"
                  required
                ></textarea>
              </div>
              <motion.button
                type="submit"
                className="bg-[#8B4513] hover:bg-[#A0522D] text-white font-bold py-3 px-6 rounded-full transition duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Send Message
              </motion.button>
            </form>
          </motion.div>

          {/* Contact Information Section */}
          <motion.div 
            className="bg-white p-8 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <h2 className="text-3xl font-semibold text-[#8B4513] mb-6">Contact Information</h2>
            <div className="space-y-6">
              <div className="flex items-center">
                <FaMapMarkerAlt className="text-[#8B4513] text-2xl mr-4" />
                <p className="text-lg text-[#5C4033]">Southern Suburbs, Cape Town, South Africa</p>
              </div>
              <div className="flex items-center">
                <FaPhone className="text-[#8B4513] text-2xl mr-4" />
                <p className="text-lg text-[#5C4033]">+277 110 55400</p>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-[#8B4513] text-2xl mr-4" />
                <p className="text-lg text-[#5C4033]">info@basketofafrica.com</p>
              </div>
            </div>
            
            <div className="mt-8">
              <h3 className="text-2xl font-semibold text-[#8B4513] mb-4 flex items-center">
                <FaClock className="mr-2" /> Business Hours
              </h3>
              <div className="space-y-2 text-lg text-[#5C4033]">
                <p>Monday - Friday: 9am - 5pm</p>
                <p>Saturday: 10am - 4pm</p>
                <p>Sunday: Closed</p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
