import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home'; 
import About from './pages/About';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Products from './pages/Products'; // Import the new Home component
import MalawianCoffeeBlogPost from './blogposts/MalawianCoffeeBlogPost';  // Fixed path
import CoffeeInMalawi from './blogposts/CoffeeInMalawi';
import GeishaCoffeeBlogPost from './blogposts/GeishaCoffeeBlogPost';

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog/malawian-coffee-blog-post" element={<MalawianCoffeeBlogPost />} /> 
            <Route path="/blog/coffee-in-malawi" element={<CoffeeInMalawi />} />
            <Route path="/blog/geisha-coffee" element={<GeishaCoffeeBlogPost />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
