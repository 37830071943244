import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import GrowAnimation from '../Animations/GrowAnimation.json';
import logo1 from '../Images/logo1.jpg';

function About() {
  const [animationStarted, setAnimationStarted] = useState(false);
  const sectionRef = useRef(null);
  const firstAnimationRef = useRef(null);
  const secondAnimationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !animationStarted) {
          setAnimationStarted(true);
          if (firstAnimationRef.current) {
            firstAnimationRef.current.goToAndPlay(0);
          }
          if (secondAnimationRef.current) {
            secondAnimationRef.current.goToAndPlay(0);
          }
        }
      },
      { threshold: 0.9 } // Trigger when 90% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [animationStarted]);

  return (
    <div ref={sectionRef} className="py-20 bg-[#5d4037] text-white relative overflow-hidden">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">Our Story</h2>
        <div className="grid md:grid-cols-2 gap-12 items-center">
          {/* Left Side: Text */}
          <div className="space-y-6 relative z-10">
            <p className="text-lg leading-relaxed">
              Basket of Africa was born from a friendship rooted in a shared passion for gardening and farming. Ben Mvula and Justin Nel met three years ago, bonding over long conversations about the untapped potential of Ben's home country, Malawi.
            </p>
            <p className="text-lg leading-relaxed">
              Their journey led to the lush landscapes of Northern Malawi, where they discovered some of the world's finest coffee. This discovery sparked the idea to connect local farmers with new markets, starting with South Africa.
            </p>
            <p className="text-lg leading-relaxed">
              Today, we're proud to support local farmers, sustainable farming practices, and fair trade, bringing the rich flavors of Africa to your doorstep while empowering local communities.
            </p>
          </div>

          {/* Right Side: Image */}
          <div className="relative ml-10 z-10">
            <img 
              src={logo1} 
              alt="Coffee plantation in Malawi" 
              className="rounded-lg shadow-2xl"
            />
          </div>
        </div>

        {/* Discover More Button */}
        <div className="mt-16 text-center z-10 relative">
          <Link to="/about" className="bg-amber-600 hover:bg-amber-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 text-lg">
            Discover Our Full Story
          </Link>
        </div>
      </div>

      {/* Bottom: First Larger Animation */}
      <div className="absolute bottom-0 left-0 w-full h-full opacity-60 z-0">
        <div className="relative w-full h-full">
          <Lottie 
            lottieRef={firstAnimationRef}
            animationData={GrowAnimation}
            loop={false}
            autoplay={false}
            style={{
              width: '35%',
              height: '35%',
              position: 'absolute',
              bottom: '-10%',
              left: '0',
              transform: 'scale(1.5)',
            }}
          />
        </div>
      </div>

      {/* Bottom: Second Smaller Animation */}
      <div className="absolute bottom-0 left-1/4 w-full h-full opacity-60 z-0">
        <div className="relative w-full h-full">
          <Lottie 
            lottieRef={secondAnimationRef}
            animationData={GrowAnimation}
            loop={false}
            autoplay={false}
            style={{
              width: '30%',
              height: '30%',
              position: 'absolute',
              bottom: '-10%',
              left: '-12%',
              transform: 'scale(0.857)',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default About;


