import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaCoffee, FaGlobeAfrica, FaLeaf, FaSeedling, FaMugHot } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import GeishaPic from '../Images/GeishaPic.jpg';

function GeishaCoffeeBlogPost() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="bg-stone-100 min-h-screen py-16">
      <div className="container mx-auto px-4 max-w-4xl">
        <motion.h1 
          className="text-5xl font-bold text-center text-stone-800 mb-8"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.5 }}
        >
          Geisha Coffee: Malawi's Hidden Treasure 
        </motion.h1>

        <motion.div 
          className="bg-white rounded-lg shadow-lg p-8 mb-8"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <p className="text-stone-600 mb-6 text-lg leading-relaxed">
            In the world of specialty coffee, few varieties command as much respect and admiration as Geisha coffee. At Basket of Africa, we're proud to bring you this exquisite coffee, sourced directly from the lush highlands of northern Malawi. Join us as we explore the captivating world of Geisha coffee and discover why our Malawian Geisha is a true hidden treasure.
          </p>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              What is Geisha Coffee?
              <FaSeedling className="ml-3 text-amber-600 text-3xl" />
            </h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              Geisha, also known as Gesha, is a variety of Coffea arabica that originated in the Gori Gesha forest of Ethiopia. Known for its distinctive floral and tea-like characteristics, Geisha coffee has taken the specialty coffee world by storm since its rise to fame in the mid-2000s.
            </p>
            <h3 className="text-2xl font-semibold text-stone-800 mb-4">Key Characteristics of Geisha Coffee:</h3>
            <ul className="list-disc list-inside text-stone-600 mb-6 pl-4 text-lg">
              <li>Exceptional floral aroma</li>
              <li>Delicate tea-like body</li>
              <li>Complex flavor profile with notes of jasmine, bergamot, and tropical fruits</li>
              <li>Lingering sweet aftertaste</li>
            </ul>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              The Journey of Geisha: From Ethiopia to Malawi
            </h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              While Geisha coffee gained international recognition through Panamanian farms, this prized variety has found a new home in the highlands of northern Malawi. At Basket of Africa, we've partnered with local farmers to cultivate this exceptional coffee in the ideal conditions of the Malawian terroir.
            </p>
            <img src={GeishaPic} alt="Geisha Coffee Fruit" className="w-full h-64 object-cover rounded-lg shadow-md mb-4" />
            <p className="text-sm text-stone-500 italic text-center">Geisha Coffee Fruit</p>
            <h3 className="text-2xl font-semibold text-stone-800 mb-4">Why Malawi is Perfect for Geisha Coffee:</h3>
            <ul className="list-disc list-inside text-stone-600 mb-6 pl-4 text-lg">
              <li>High altitude growing regions (1,500-2,000 meters above sea level)</li>
              <li>Ideal climate with distinct wet and dry seasons</li>
              <li>Generations of coffee-growing expertise</li>
            </ul>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              Basket of Africa's Malawian Geisha: A Taste of Excellence
            </h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              Our commitment to quality starts at the source. We work closely with smallholder farmers in northern Malawi, ensuring that every Geisha coffee plant receives the care and attention it deserves. This hands-on approach results in a cup profile that is truly exceptional:
            </p>
            <ul className="list-none text-stone-600 mb-6 pl-4 text-lg">
              <li className="mb-2"><strong>Aroma:</strong> Intoxicating jasmine and orange blossom scents</li>
              <li className="mb-2"><strong>Flavor:</strong> Delicate notes of bergamot, honey, and tropical fruits</li>
              <li className="mb-2"><strong>Body:</strong> Silky and tea-like</li>
              <li className="mb-2"><strong>Acidity:</strong> Bright and complex</li>
              <li className="mb-2"><strong>Aftertaste:</strong> Long-lasting sweetness with floral hints</li>
            </ul>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              Why Choose Malawian Geisha?
            </h2>
            <ol className="list-decimal list-inside text-stone-600 mb-6 pl-4 text-lg">
              <li className="mb-2">Unique Flavor Profile: Our Malawian Geisha offers a distinct taste experience that sets it apart from other origins.</li>
              <li className="mb-2">Supporting Local Communities: By choosing our coffee, you're directly supporting smallholder farmers in Malawi.</li>
              <li className="mb-2">Sustainable Practices: We're committed to environmentally friendly cultivation methods.</li>
              <li className="mb-2">Exclusivity: Experience a rare and sought-after coffee variety.</li>
              <li className="mb-2">Quality Assurance: Our rigorous quality control ensures you receive only the finest beans.</li>
            </ol>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              Brewing the Perfect Cup of Malawian Geisha
            </h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              To fully appreciate the complex flavors of our Geisha coffee, we recommend:
            </p>
            <ul className="list-disc list-inside text-stone-600 mb-6 pl-4 text-lg">
              <li>Using freshly roasted beans</li>
              <li>Grinding just before brewing</li>
              <li>Opting for manual brewing methods like pour-over or Chemex</li>
              <li>Water temperature around 195-205°F (90-96°C)</li>
              <li>A brewing ratio of 1:15 (coffee to water)</li>
            </ul>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4">Join the Geisha Revolution with Basket of Africa</h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              As coffee enthusiasts and importers, we at Basket of Africa are thrilled to share the exquisite taste of Malawian Geisha with the world. By choosing our coffee, you're not just indulging in a superior cup – you're supporting sustainable farming practices and contributing to the growth of Malawi's coffee industry.
            </p>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              Experience the extraordinary flavors of Malawian Geisha coffee today. Visit our online store or contact us to learn more about our sourcing practices and available varieties. Let Basket of Africa take you on a journey through the captivating world of Geisha coffee, straight from the heart of Malawi to your cup.
            </p>
          </div>

          <div className="text-center">
            <Link to="/contact">
              <motion.button 
                className="bg-amber-600 hover:bg-amber-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 text-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Contact Us
              </motion.button>
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default GeishaCoffeeBlogPost;