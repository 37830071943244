import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';  // Import framer-motion
import Misuku from '../Images/Misuku.jpg';
import GeishaPic from '../Images/GeishaPic.jpg';
import MalawiCoffeePic from '../Images/MalawiCoffeePic.jpg';

function Blog() {
  const blogPosts = [
    {
      title: 'Malawian Coffee: A Hidden Gem',
      excerpt: 'Discover the wonderful Malawian coffee sector...',
      date: 'September, 2023',
      link: "/blog/malawian-coffee-blog-post",
      image: Misuku
    },
    {
      title: 'The Economic Impact of Malawi Coffee Exports',
      excerpt: 'A Growing Opportunity',
      date: 'May 1, 2024',
      link: "/blog/coffee-in-malawi",
      image: MalawiCoffeePic
    },
    {
      title: 'A Guide to Geisha Coffee',
      excerpt: 'Explore the rich flavors of Malawian Geisha coffee beans',
      date: 'September, 2024',
      link: "/blog/geisha-coffee",
      image: GeishaPic
    },
  ];

  // Animation settings for each blog post card
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 60,
        damping: 20,
      },
    },
  };

  return (
    <div className="py-16 bg-stone-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-stone-800">From Our Blog</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }} // Ensures animation only runs once when in view
              variants={cardVariants}
            >
              <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 text-stone-800">{post.title}</h3>
                <p className="text-stone-600 mb-4">{post.excerpt}</p>
                <p className="text-sm text-stone-500">{post.date}</p>
                <Link to={post.link} className="mt-4 inline-block text-amber-600 hover:text-amber-700 font-bold">
                  Read More
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
