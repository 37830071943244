import React from 'react';
import { motion } from 'framer-motion';
import { FaSeedling } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import MalawiCoffeePic from '../Images/MalawiCoffeePic.jpg';

function MalawianCoffeeExportsArticle() {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="bg-stone-100 min-h-screen py-16">
      <div className="container mx-auto px-4 max-w-4xl">
        <motion.h1 
          className="text-5xl font-bold text-center text-stone-800 mb-8"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.5 }}
        >
          The Economic Impact of Malawi's Coffee Exports
        </motion.h1>

        <motion.div 
          className="bg-white rounded-lg shadow-lg p-8 mb-8"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <p className="text-stone-600 mb-6 text-lg leading-relaxed">
            Nestled in the heart of southeastern Africa, Malawi is a country with a rich agricultural heritage where coffee plays an increasingly vital role in the national economy. As a leading importer and exporter of Malawian coffee, our company has witnessed firsthand the transformative power of this aromatic bean on rural communities and the country's economic landscape.
          </p>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              Malawi's Coffee Industry: A Snapshot 
              <FaSeedling className="ml-3 text-amber-600 text-3xl" />
            </h2>
            <ul className="list-disc list-inside text-stone-600 mb-6 pl-4 text-lg">
              <li>Agriculture contributes approximately 45% to Malawi's GDP</li>
              <li>Over 90% of export earnings come from agriculture</li>
              <li>Coffee exports reached $4.39 million in 2022</li>
              <li>Coffee ranks as the 20th most exported product in Malawi</li>
            </ul>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              The Rise of Smallholder Farmers
            </h2>
            <p className="text-stone-600 mb-4 text-lg leading-relaxed">
              The majority of Malawi's coffee production comes from smallholder farmers, particularly in the Northern Region. These enterprising individuals have united to form cooperatives, such as the Mzuzu Coffee Planters Cooperative Union, enhancing their market position and product quality.
            </p>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              Benefits of cooperatives include improved bargaining power, enhanced market access, knowledge sharing for better quality production, and access to specialty coffee markets.
            </p>
            <img src={MalawiCoffeePic} alt="Malawian Coffee Farm" className="w-full h-64 object-cover rounded-lg shadow-md mb-4" />
            <p className="text-sm text-stone-500 italic text-center"></p>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              Challenges and Opportunities
            </h2>
            <p className="text-stone-600 mb-4 text-lg leading-relaxed">
              While Malawi's coffee sector shows promise, it faces several challenges:
            </p>
            <ol className="list-decimal list-inside text-stone-600 mb-6 pl-4 text-lg">
              <li>Competition with maize due to government subsidies</li>
              <li>Coffee Wilt Disease (CWD) affecting crop yields and quality</li>
              <li>Global market price fluctuations impacting farmer incomes</li>
              <li>Need for improved infrastructure and processing facilities</li>
            </ol>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              Despite these hurdles, opportunities abound in the growing specialty coffee market, potential for Fair Trade certification, and government initiatives promoting coffee as an alternative cash crop.
            </p>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              Our Role in Malawi's Coffee Journey
            </h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              As a dedicated importer and exporter of Malawian coffee, our company plays a crucial role in bridging local farmers with international markets. We're committed to providing fair prices, investing in quality improvement initiatives, promoting Malawian coffee in global specialty markets, and supporting sustainable farming practices.
            </p>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-stone-800 mb-4 flex items-center">
              The Future of Malawian Coffee
            </h2>
            <p className="text-stone-600 mb-6 text-lg leading-relaxed">
              The potential for growth in Malawi's coffee sector is substantial. With continued investment in farmer training, disease-resistant varieties, and marketing efforts, Malawian coffee is poised to become a significant player in the global specialty coffee market.
            </p>
          </div>

          <div className="text-center">
            <Link to="/contact">
              <motion.button 
                className="bg-amber-600 hover:bg-amber-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 text-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Contact Us
              </motion.button>
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default MalawianCoffeeExportsArticle;