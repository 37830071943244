import React from 'react';
import { Link } from 'react-router-dom';
import image4 from '../Images/image4.jpg';
import Lottie from 'lottie-react';
import GrowAnimation from '../Animations/GrowAnimation.json';

function Hero() {
  return (
    <div className="bg-[#F5DEB3] text-[#5C4033] min-h-screen flex items-center justify-between px-4 relative overflow-hidden">
      <div className="container mx-auto flex flex-col md:flex-row items-center relative z-10">
        {/* Left Side: Text */}
        <div className="md:w-1/2">
          <h1 className="text-5xl font-bold mb-4">Fresh African Produce Delivered</h1>
          <p className="text-2xl mb-8">From Our Farms to Your Doorstep</p>
          <Link to="/products" className="bg-[#8B4513] hover:bg-[#A0522D] text-white font-bold py-3 px-6 rounded transition duration-300">
            Order Online
          </Link>
        </div>

        {/* Right Side: Image */}
        <div className="md:w-1/2 mt-8 md:mt-0">
          <img 
            src={image4} 
            alt="Fresh African Produce" 
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>

      {/* Animation */}
      <div className="absolute bottom-0 left-0 w-full h-full opacity-60 z-0">
        <Lottie 
          animationData={GrowAnimation}
          loop={false}
          autoplay={true}
          style={{
            width: '40%',
            height: '40%',
            position: 'absolute',
            bottom: '-10%',
            left: '0',
            transform: 'scale(1.5)',
          }}
        />
      </div>
    </div>
  );
}

export default Hero;