import React from 'react';
import Lottie from 'react-lottie';
import farmingAnimation from '../Animations/FarmingAnimation.json'; 

function Newsletter() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: farmingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="bg-[#5d4037] text-[#5C4033] py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-4 text-center text-white">Stay Updated with Our Latest News</h2>
        <p className="text-lg text-white text-center mb-0">Be the first to hear about our new stock deliveries</p>
        
        {/* Lottie Animation in Between Headings and Signup Box */}
        <div className="w-full flex justify-center mb-3">
          <div className="w-96 h-96">
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        </div>

        {/* Subscription Form */}
        <form className="max-w-lg mx-auto">
          <div className="flex">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-grow p-4 rounded-l-md focus:outline-none focus:ring-2 focus:ring-[#8B4513] placeholder-[#5C4033] text-[#5C4033]"
            />
            <button
              type="submit"
              className="bg-[#8B4513] hover:bg-[#A0522D] text-white font-bold py-4 px-6 rounded-r-md transition duration-300"
            >
              Subscribe
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Newsletter;