import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-stone-800 text-stone-300 pt-12 pb-6">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          <div>
            <h3 className="text-2xl font-bold mb-4 text-amber-100">Basket of Africa</h3>
            <p className="mb-4">Bringing the finest African produce to your doorstep.</p>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-amber-100">Quick Links</h4>
            <ul className="space-y-2">
              {['Home', 'About', 'Products', 'Blog', 'Contact'].map((item) => (
                <li key={item}>
                  <Link to={`/${item.toLowerCase()}`} className="hover:text-amber-200 transition duration-300">
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-amber-100">Contact Us</h4>
            <address className="not-italic">
              <p>Southern Suburbs, Cape Town</p>
              <p>Western Cape, South Africa</p>
              <p className="mt-2">Phone: +271 105 5400</p>
              <p>Email: info@basketofafrica.com</p>
            </address>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-amber-100">Follow Us</h4>
            <div className="flex space-x-4">
              {[FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn].map((Icon, index) => (
                <a key={index} href="2" className="bg-stone-700 p-2 rounded-full hover:bg-amber-700 transition duration-300">
                  <Icon size={20} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="border-t border-stone-700 pt-6 text-center">
          <p className="text-stone-400">&copy; {new Date().getFullYear()} Basket of Africa. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;