import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="bg-[#8B4513] text-white">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">Basket of Africa</Link>
        <nav>
          <ul className="flex space-x-4">
            <li><Link to="/" className="hover:text-orange-300">Home</Link></li>
            <li><Link to="/about" className="hover:text-orange-300">About</Link></li>
            <li><Link to="/products" className="hover:text-orange-300">Products</Link></li>
            <li><Link to="/blog" className="hover:text-orange-300">Blog</Link></li>
            <li><Link to="/contact" className="hover:text-orange-300">Contact</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
