import React from 'react';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import image2 from '../Images/image2.jpg';
import logo1 from '../Images/logo1.jpg';
import wheelburrowAnimation from '../Animations/Wheelburrow.json';

const Founder = ({ name, role, bio, imageUrl }) => (
  <div className="flex flex-col items-center md:flex-row md:items-start mb-12">
    <img 
      src={imageUrl} 
      alt={name} 
      className="w-48 h-48 rounded-full object-cover mb-4 md:mb-0 md:mr-6"
    />
    <div>
      <h3 className="text-2xl font-semibold mb-2">{name}</h3>
      <p className="text-lg text-[#8B4513] mb-2">{role}</p>
      <p className="text-[#5C4033]">{bio}</p>
    </div>
  </div>
);

function About() {
  return (
    <div className="bg-[#F5DEB3] py-12"> {/* Reduced top and bottom padding */}
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-center text-[#5C4033] mb-12">About Basket of Africa</h1>
        
        <section className="mb-16 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
            <h2 className="text-3xl font-semibold mb-6 text-[#8B4513]">Our Mission</h2>
            <p className="text-lg text-[#5C4033] mb-4">
              At Basket of Africa, we're dedicated to bringing the finest African produce directly to your doorstep. 
              Our mission is to showcase the rich diversity of African agriculture while supporting local 
              communities and promoting healthy eating habits around the world.
            </p>
            <p className="text-lg text-[#5C4033] mb-4">
              We work closely with local farmers across the African continent to ensure sustainable farming 
              practices and fair trade, fostering economic growth and environmental responsibility.
            </p>
          </div>
          
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img 
              src={image2} 
              alt="African Farmers" 
              className="w-full h-auto max-w-md rounded-lg shadow-lg"
            />
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-[#8B4513]">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {['Sustainability', 'Fair Trade', 'Community Support', 'Quality and Freshness'].map((value, index) => (
              <motion.div 
                key={index} 
                className="bg-[#D2B48C] p-6 rounded-lg shadow-md hover:shadow-lg"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <h3 className="text-xl font-semibold mb-2 text-[#5C4033]">{value}</h3>
                <p className="text-[#5C4033]">We are committed to {value.toLowerCase()} in every aspect of our business.</p>
              </motion.div>
            ))}
          </div>
        </section>

        <section className="mb-12"> {/* Reduced margin-bottom */}
          <h2 className="text-3xl font-semibold mb-8 text-[#8B4513]">Meet Our Founders</h2>
          <Founder 
            name="Ben Mvula"
            role="Co-Founder"
            bio="Ben Mvula, with his deep roots in Malawian agriculture and passion for sustainable farming, leads Basket of Africa's mission to bring the continent's finest produce to the world stage."
            imageUrl={logo1}
          />
          <Founder 
            name="Justin Nel"
            role="Co-Founder"
            bio="Justin brings his expertise in logistics and international trade to ensure that Basket of Africa delivers not just quality products, but also a seamless farm-to-table experience for our customers."
            imageUrl={logo1}
          />
        </section>

        <div className="text-center mb-0"> {/* Reduced margin-bottom */}
          <button className="bg-[#8B4513] hover:bg-[#A0522D] text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300">
            Get in Touch
          </button>
        </div>

        {/* Wheelburrow Animation */}
        <div className="flex justify-center mb-0 ml-10"> 
          <Lottie 
            animationData={wheelburrowAnimation}
            style={{ width: '500px', height: '300px' }} 
          />
        </div>
      </div>
    </div>
  );
}

export default About;