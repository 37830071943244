import React from 'react';
import image3 from '../Images/image3.jpg';
import image1 from '../Images/image1.jpg';
import Misuku from '../Images/Misuku.jpg';
import GeishaPic from '../Images/GeishaPic.jpg';
import MalawiCoffeePic from '../Images/MalawiCoffeePic.jpg';

import { Link } from 'react-router-dom';


function Blog() {
  const featuredPost = {
    title: 'Malawian Coffee: A Hidden Gem in African Coffee Production',
    excerpt: 'Discover the rich history and unique flavors of Malawian coffee...',
    date: 'September, 2024',
    link: "/blog/malawian-coffee-blog-post",
    image: Misuku
  }

  const blogPosts = [
    {
      title: 'Malawian Coffee: A Hidden Gem in African Coffee Production',
      excerpt: 'Discover the rich history and unique flavors of Malawian coffee...',
      date: 'September, 2024',
      link: "/blog/malawian-coffee-blog-post",
      image: Misuku
    },
    {
      title: 'The Economic Impact of Malawi Coffee Exports',
      excerpt: 'A Growing Opportunity',
      date: 'May 1, 2023',
      link: "/blog/coffee-in-malawi", // Add a link for this post
      image: MalawiCoffeePic
    },
    {
      title: 'A Guide to Geisha Coffee',
      excerpt: 'Explore the rich flavors of Malawian Geisha coffee beans',
      date: 'September, 2024',
      link: "/blog/geisha-coffee", // Add a link for this post
      image: GeishaPic
    },
  ];

  return (
    <div className="bg-[#F5DEB3] py-12">
      <div className="container mx-auto px-4">
        {/* Featured Story Section */}
        <section className="mb-10 max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-[#5C4033] mb-6">Featured Story of the Month</h2>
          <div className="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
            <img 
              src={featuredPost.image} 
              alt={featuredPost.title} 
              className="w-full lg:w-2/5 h-48 lg:h-64 object-cover" 
            />
            <div className="p-4 lg:p-6 flex flex-col justify-between">
              <div>
                <h3 className="text-2xl font-semibold text-[#8B4513] mb-3">{featuredPost.title}</h3>
                <p className="text-md text-[#5C4033] mb-4">{featuredPost.excerpt}</p>
              </div>
              <p className="text-sm text-gray-500">{featuredPost.date}</p>
              <Link 
                to={featuredPost.link} 
                className="mt-4 bg-[#8B4513] hover:bg-[#A0522D] text-white font-bold py-2 px-4 rounded-full transition duration-300 text-center"
              >
                Read Full Story
              </Link>
            </div>
          </div>
        </section>

        {/* Blog Posts Section */}
        <h1 className="text-3xl font-bold text-center text-[#5C4033] mb-8">Basket of Africa Blog</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogPosts.map((post, index) => (
            <div key={index} className="bg-white border rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
              <img src={post.image} alt={post.title} className="w-full h-40 object-cover" />
              <div className="p-5">
                <h2 className="text-xl font-semibold text-[#8B4513] mb-3">{post.title}</h2>
                <p className="text-md text-[#5C4033] mb-3">{post.excerpt}</p>
                <p className="text-sm text-gray-500 mb-4">{post.date}</p>
                <Link 
                  to={post.link} 
                  className="bg-[#8B4513] hover:bg-[#A0522D] text-white font-bold py-2 px-4 rounded-full transition duration-300 inline-block"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;